import type { ReactNode, MouseEvent } from 'react'
import { forwardRef } from 'react'

import assertNever from '@src/lib/assertNever'
import type { TypographyVariant } from '@src/theme'
import { LoadingIndicator } from '@ui/LoadingIndicator'
import Typography from '@ui/Typography/Typography'

import * as styles from './Button.css'
import normalizeColor from './utils/normalizeColor'

export type Variant = 'solid' | 'outline' | 'dashed' | 'pastel' | 'ghost'
export type Size = 'default' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
export type Color = 'default' | 'green' | 'red' | 'white' | 'purple' | 'subtle'
export type IconPosition = 'left' | 'right'

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: Variant
  size?: Size
  color?: Color
  icon?: ReactNode
  iconPosition?: IconPosition
  /**
   * Use `IconButton` instead.
   * This property is only used by the IconButton component to indicate the Button that only renders an icon.
   * @private
   */
  onlyIcon?: boolean
  loading?: boolean
  fullWidth?: boolean
  children: ReactNode
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'solid',
      size = 'default',
      color: _color = 'default',
      icon,
      iconPosition: _iconPosition = 'left',
      onlyIcon = false,
      loading,
      disabled,
      children,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const color = normalizeColor({ variant, color: _color, onlyIcon })

    const sizeTypographyVariants = ((): TypographyVariant => {
      switch (size) {
        case 'lg':
          return 'body'
        case 'md':
          return 'callout'
        case 'sm':
          return 'footnote'
        case 'xs':
        case 'xxs':
          return 'caption1'
        case 'default':
          return 'callout'
        default:
          assertNever(size, 'Unexpected Button size variant used')
      }
    })()

    const iconPosition = icon ? _iconPosition : undefined
    const isLeftIcon = icon ? iconPosition === 'left' : false
    const isRightIcon = icon ? iconPosition === 'right' : false
    const iconContent =
      isLeftIcon || isRightIcon ? (
        <span aria-hidden className={styles.icon({ onlyIcon, iconPosition, loading })}>
          {icon}
        </span>
      ) : null

    const isDisabled = disabled || loading

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (isDisabled) {
        event.preventDefault()
        return
      }

      if (props.onClick) {
        props.onClick(event)
      }
    }

    return (
      <button
        {...props}
        aria-disabled={isDisabled}
        ref={ref}
        className={styles.root({
          variant,
          size,
          color,
          icon: !!icon,
          iconPosition,
          onlyIcon,
          fullWidth,
        })}
        onClick={handleClick}
      >
        {isLeftIcon ? iconContent : null}
        {!onlyIcon && children ? (
          <Typography
            variant={sizeTypographyVariants}
            color="inherit"
            className={styles.text({ iconPosition, loading })}
          >
            {children}
          </Typography>
        ) : null}
        {isRightIcon ? iconContent : null}
        {loading ? (
          <LoadingIndicator
            className={styles.loadingIndicator}
            variant={onlyIcon ? 'circular' : 'dotted'}
            size={onlyIcon ? 14 : undefined}
          />
        ) : null}
      </button>
    )
  },
)

export default Button
